<template>
    <div>
        <h4> Action Args Interfaces </h4>
        <table v-if="action_options">
            <tr>
                <!-- <th>id</th>
                <th>name</th>
                <th>args_spec</th> -->
            </tr>
            <tbody  v-for="ao in action_options" :key="ao.id">
                <tr>
                    <td colspan=2>{{ao.id}}</td>
                    <td colspan="3">{{ao.fnc_name}}</td>

                </tr>
                <tr>

                    <td colspan="5">
                        <table>
                            
                            <tr v-for="arg in ao.args_spec" :key="arg.name">
                                <td> <v-text-field label="name" v-model="arg.name" outlined dense hide-details ></v-text-field></td>
                                <td> <v-text-field label="type" v-model="arg.type" outlined dense hide-details ></v-text-field></td>
                                <td> <v-text-field label="value" v-model="arg.value" outlined dense hide-details ></v-text-field></td>
                                <td> <v-text-field label="example" v-model="arg.example" outlined dense hide-details ></v-text-field></td>
                                <td> <v-btn class="ma-0"  elevation="2" x-small fab  @click="rmv_arg(ao, arg)" ><v-icon class="ma-0" color="red" small>mdi-trash-can</v-icon></v-btn></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>

                                    <v-btn class="ma-0"  elevation="2" x-small  fab @click="add_arg(ao)" ><v-icon small class="ma-0">mdi-plus</v-icon></v-btn>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">

                        <v-btn class="ma-0"  elevation="2" x-small fab light  @click="do_save_action_options_operations(ao)" ><v-icon class="ma-0" small >mdi-content-save</v-icon></v-btn>
                        <v-btn class="ml-1"  elevation="2" x-small fab light  @click="do_save_action_options_operations(ao)" ><v-icon class="ma-0" small >mdi-check</v-icon></v-btn>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'maint-action-options',
    components:{
    },
    emits:[
    ],
    data(){return {
        action_options:undefined
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_get_action_options_operations()
    
    },
    methods:{
        ...mapActions(['action_options_operations']),
        do_get_action_options_operations(){
            this.action_options_operations({qs:{action:'get_all'},data:{}}).then((r)=>{
                this.action_options = r.data
            })
        },
        do_save_action_options_operations(ao){
            this.action_options_operations({qs:{action:'save'},data:ao}).then((r)=>{
                this.action_options = r.data
            })
        },
        add_arg(ao){
            ao.args_spec.push({
                name:'new',
                type:'int',
                value:'',
                example:'',
            })
        },
        rmv_arg(ao,arg){
            ao.args_spec = ao.args_spec.filter(x=> x.name != arg.name)
        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>