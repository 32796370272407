<template>
    <div>
         <v-progress-circular v-if="is_loading" indeterminate color="white" ></v-progress-circular>
        <table v-if="persons">
            <tr>
                <th>id</th>
                <!-- <th>name</th> -->
                <th>nickname</th>
                <th>last login</th>
                <th>clearance</th>
                <th>on plan</th>
                <th>wallets</th>
                <th>teams</th>
                <th>balance</th>
                <th></th>
            </tr>
            <tr v-for="p in persons" :key="p.id">
                <td>{{p.id}}</td>
                <!-- <td>{{p.name}}</td> -->
                <td>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn  v-bind="attrs" v-on="on" class="ma-0" elevation="2" color="white" light block small  >  {{p.nickname}} </v-btn>
                        </template>
                        <span >{{p.name}}</span>
                    </v-tooltip>
                    
                </td>
                <td>{{p.last_login}}</td>
                <td>
                <v-select v-model="p.clearance" :items="[0,1,2,3,4,5]" label="p.clearance" dense  solo hide-details >
                    <template v-slot:selection="{ item, index }">
                        Level {{item}}
                        </template>
                    <template v-slot:item="{ item, index }">
                        {{item}}
                    </template>
                </v-select>
                    <!-- {{p.clearance}} -->
                
                </td>
                <td>{{p.paid_plan}}</td>
                <td> <v-btn class="ma-0"  elevation="2" small block  @click="0" >auth {{p.auth_wallets.length}}</v-btn></td>
                <td> <v-btn class="ma-0"  elevation="2" small block  @click="0" > {{p.teams.length}} teams</v-btn></td>
                <td>
                    <v-text-field  v-model="p.credit_balance" outlined dense type="number" step="1000" hide-details></v-text-field>
                    <!-- {{p.credit_balance}} -->
                </td>
                <td>
                    <v-btn class="ma-0"  elevation="2" small light block @click="update_person(p)" ><v-icon class="ma-0">mdi-content-save</v-icon></v-btn>
                </td>

            </tr>

        </table>
        <!-- <pre>
            {{persons}}
        </pre> -->
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'maint-person-users',
    components:{
    },
    emits:[
    ],
    data(){return {
        persons: undefined,
        is_loading:false,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.is_loading = true
        this.person_user_actions({qs:{action:'get_all_persons'},data:{}}).then((r)=>{this.persons = r.data; this.is_loading = false})
        
    },
    methods:{
        ...mapActions(['person_user_actions']),
        update_person(person){
            this.is_loading = true
            this.person_user_actions({qs:{action:'update_person'},data:person}).then((r)=>{this.persons = r.data ; this.is_loading = false})

        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>