import { render, staticRenderFns } from "./clearance-guard.vue?vue&type=template&id=4436450e&scoped=true&"
import script from "./clearance-guard.vue?vue&type=script&lang=js&"
export * from "./clearance-guard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4436450e",
  null
  
)

export default component.exports