<template>
    <div>
        <h5>Latest updates </h5>
        <v-data-iterator :items="update_times">
            <template v-slot:default="{ items, isExpanded, expand }">
                <table>
                    <tr v-for="item in items" :key="item.name">
                        <th>{{ item.name }}</th>
                        <td v-if="item.type =='time'" :class="{'val-bad':item.val > 1800, 'val-good': item.val < 1800 }"> 
                            <seconds-amount :amount="item.val"></seconds-amount>
                        </td>
                        <td v-else>
                            {{ item.val }} x
                        </td>
                    </tr>
                </table>
            </template>
        </v-data-iterator>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import SecondsAmount from '../seconds-amount.vue'
export default {
    name: 'maint-latest-update-times',
    components:{
        SecondsAmount
    },
    emits:[
    ],
    data(){return {
        update_times:undefined
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_maint_get_latest_update_times()
    
    },
    methods:{
        ...mapActions(['maint_get_latest_update_times']),
        do_maint_get_latest_update_times(){
            this.maint_get_latest_update_times({qs:{},data:{}}).then((r)=>{this.update_times= r.data})
        },
        
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
.val-bad{ color:hsl(2, 100%, 81%)}
.val-good{ color:hsl(156, 100%, 81%)}

</style>