<template>
    <div v-if="selected_team.clearance_level >= minimumClearance">
        <slot ></slot>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'clearance-guard',
    components:{
    },
    emits:[
    ],
    data(){return {
        }
    },
    props: { 
        //propName:{type:String,default:''},
        minimumClearance:{type:Number,default:0},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState(['user_data','selected_team']),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>