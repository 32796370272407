<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="6">
                    <v-sheet rounded="sm">
                        <clearance-guard :minimumClearance="0">
                            <maint-latest-update-times></maint-latest-update-times>
                        </clearance-guard>
                        <div style="display:flex; flex-direction:rows;text-align:center;flex-wrap:wrap;">

                            <a class="ma-1 maint-link" href="http://134.209.91.206:9231/maint"> Go To Price Checker Maintainance </a>
                            <a class="ma-1 maint-link" href="/"> Go To BC pulse Maintainance </a>
                            <a class="ma-1 maint-link" href="/"> Go To Sim Maintainance </a>
                            <a class="ma-1 maint-link" href="/"> Go To TG Maintainance </a>
                            <a class="ma-1 maint-link" href="/"> Go To Executor Maintainance </a>
                        </div>
                    </v-sheet>
                </v-col>
                <v-col sm="12" md="6">
                    <clearance-guard :minimumClearance="0">
                        <v-sheet rounded="sm">
                            <h3>Users</h3>
                            <maint-person-users></maint-person-users>
                        </v-sheet>
                    </clearance-guard>
                </v-col>
                <v-col cols="12" md="12">
                    <v-sheet rounded="sm">
                        <clearance-guard :minimumClearance="0">
                            <maint-action-options></maint-action-options>
                        </clearance-guard>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
import ClearanceGuard from './dev/clearance-guard.vue'
import MaintActionOptions from './dev/maint-action-options.vue'
import MaintLatestUpdateTimes from './dev/maint-latest-update-times.vue'
// End Component Imports
import { mapState,mapActions } from 'vuex'
import MaintPersonUsers from './dev/maint-person-users.vue'
export default {
    name: 'view-dev',
    components:{
        MaintLatestUpdateTimes,
        ClearanceGuard,
        MaintActionOptions,
        MaintPersonUsers
    },
    emits:[
    ],
    data(){return {
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">

.maint-link{
    text-decoration: none;
    background: rgba(255, 255, 255, 0.254);
    padding:0.2em;
    border-radius:3px;
    transition:0.5s;
}
.maint-link:hover{
    background: rgba(255, 255, 255, 0.502);

}
</style>